<template>
  <!-- 代理商新增，修改 -->
  <div>
    <el-dialog title="编辑" :visible.sync="dialogVisible" width="30%">
      <el-form :model="form" :rules="rules" label-width="120px" ref="form">
        <el-form-item label="账号" required prop="login_name">
          <el-input v-model="form.login_name" placeholder="账号"></el-input>
        </el-form-item>
        <el-form-item label="密码" v-if="!form.id">
          <el-input v-model="form.pwd" placeholder="密码"></el-input>
        </el-form-item>
        <el-form-item label="电话" required prop="mobile">
          <el-input v-model="form.mobile" placeholder="电话"></el-input>
        </el-form-item>
        <el-form-item label="姓名" required prop="name">
          <el-input v-model="form.name" placeholder="姓名"></el-input>
        </el-form-item>
        <el-form-item label="职位" required prop="type">
          <el-radio-group v-model="form.type" @input="changeType">
            <el-radio :label="t.type" v-for="t in types" :key="t.id">{{
              t.short_name
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="form.id"
          type="primary"
          @click="updateForm"
          :loading="loading"
          >更 新</el-button
        >
        <el-button v-else type="primary" :loading="loading" @click="addForm"
          >新 增</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { ObjectChangedValue } from "@/js/utils.js";
import { addRule } from "./addRule.js";
export default {
  data() {
    return {
      rules: addRule,
      form: {},
      temp: {},
      dialogVisible: false,
      loading: false,
      types: [],
    };
  },

  mounted() {
    this.getTypes();
  },

  methods: {
    changeType(type) {
      this.types.forEach((v) => {
        if (v.type == type) {
          this.form.identity_id = v.id;
          this.form.short_name = v.short_name;
        }
      });
    },
    getTypes() {
      this.$axios({
        url: "/authorization/managerIdentity/queryListPage",
        params: { parent_id: 120 },
      }).then((res) => {
        this.types = res.data.data;
      });
    },

    addForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.form.is_employees=1
          this.form.cooperator_id = JSON.parse(
            localStorage.getItem("managerInfo")
          ).cooperator_id;
          this.$axios({
            url: "/authorization/managerUser/insert",
            method: "post",
            data: this.form,
          })
            .then((res) => {
              this.$notify({
                message: "新增成功",
                duration: 2000,
                type: "success",
              });
              this.dialogVisible = false;
              this.loading = false;
              this.$emit("success", 1);
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },

    updateForm() {
      this.form.parent_id = this.coop_id || 0;
      this.$refs.form.validate((valid) => {
        if (valid) {
          const data = ObjectChangedValue(this.temp, this.form);
          if (Object.keys(data).length == 0) {
            this.$message("没有修改数据，无需更新");
            return;
          }
          this.loading = true;
          this.$axios({
            url: "/authorization/managerUser/update",
            method: "post",
            data: {
              id: this.form.id,
              ...data,
            },
          })
            .then((res) => {
              this.$notify({
                message: "更新成功",
                duration: 2000,
                type: "success",
              });
              this.dialogVisible = false;
              this.loading = false;
              this.$emit("success", 1);
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },

    open(info) {
      if (info && info.id) {
        this.temp = Object.assign({}, info);
        this.form = info;
      } else {
        this.form = {};
        this.temp = {};
      }
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
</style>