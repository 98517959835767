export let addRule = {
    name: [
        { required: true, message: "请填写姓名", trigger: "blur" },
    ],
    mobile: [
        { required: true, message: "请填写电话", trigger: "blur" },
    ],
    type: [
        { required: true, message: "请选择职位", trigger: "change" },
    ],
    login_name: [
        { required: true, message: "请填写登录账号", trigger: "blur" },
    ],

}

