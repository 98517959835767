<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item>
        <el-button type="primary" @click="add">新增员工账号</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
     <template #status="{ row }">
         <el-tag size="small" type="danger" v-if="row.status === 1"
          >冻结</el-tag
        >
        <el-tag size="small" type="success" v-if="row.status === 2">正常</el-tag>
      </template>
      <template #handle="{ row }">
        <el-button size="small" type="primary" @click="update(row)"
          >编辑</el-button
        >
         <el-button size="small" v-if="row.status === 2" type="success" @click="statusUpdate(row, 1)"
          >冻结</el-button
        >
        <el-button size="small" v-if="row.status === 1"  type="danger" @click="statusUpdate(row, 2)"
          >解冻</el-button
        >
      </template>
    </auto-table>

    <editStaff ref="edit" @success="getList(1)"></editStaff>
  </div>
</template>

<script>
import editStaff from "./components/editStaff.vue";
export default {
  components: {
    editStaff,
  },

  data() {
    return {
      DataList: [],
      Option: [
        { name: "姓名", value: "name" },
        { name: "电话", value: "mobile" },
        { name: "职位", value: "short_name" },
        { name: "登录账号", value: "login_name" },
         { name: "状态", value: "status",type:'custom' },
        { name: "创建时间", value: "create_time" },
        { name: "操作", value: "handle", type: "custom" },
      ],
      Total: 0,
      type: "",
      cooperator_id: JSON.parse(localStorage.getItem("managerInfo"))
        .cooperator_id,
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    add() {
      this.$refs.edit.open();
    },
    update(v) {
      this.$refs.edit.open({ ...v });
    },
    statusUpdate(row, status) {
      this.$axios({
        url: "/authorization/managerUser/update",
        method: "post",
        data: {
          id: row.id,
          status,
        },
      }).then((res) => {
        this.$message("成功");
        this.getList(1);
      });
    },
    //列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/authorization/managerUser/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          cooperator_id: this.cooperator_id,
          type: this.type || null,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>